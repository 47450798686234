$(document).ready(function (e) {
  var $header = $(".m_main-header");
  $header.each(function (i, thisHeader) {
    m_main_header__init($(thisHeader));
  });
});

function m_main_header__init(thisHeader) {
  var $mobileButton = thisHeader.find(".m_main-header--menu-btn");
  // $nav = thisHeader.find('.m_main-header--main-nav');
  $mobileButton.on("click", function (event) {
    event.preventDefault();
    thisHeader.toggleClass("isOpen");
  });
}
