$(document).ready(function (e) {
  var timeline = $("div.m_timeline");
  if (timeline.length > 0) {
    m_timeline__updateLazyLoading(timeline);
    m_timeline__lazyload(timeline);
    m_timeline__refreshItemsHolder(timeline);
    m_timeline__init(timeline);
  }
});

function m_timeline__updateLazyLoading(timeline) {
  var images = timeline.find("img[data-src]");
  // load images that have entered the viewport
  $(images).each(function (index) {
    if (m_timeline__isElementInViewport(this)) {
      $(this).attr("src", $(this).attr("data-src"));
      $(this).removeAttr("data-src");
    }
  });
}

function m_timeline__lazyload(timeline) {
  timeline.find(".m_timeline--item").each(function () {
    // add the placeholders
    var $imageTag = $(this).find(".gallery-container > img[data-src]");
    if ($(this).hasClass("m_timeline--item__portrait")) {
      $imageTag.attr("src", "/assets/imgs/life/placeholder-portrait.gif");
    } else {
      $imageTag.attr("src", "/assets/imgs/life/placeholder-wide.gif");
    }
  });

  // Lazy loading - Based on http://developer.telerik.com/featured/lazy-loading-images-on-the-web/
  $(window).on("DOMContentLoaded load resize scroll", function () {
    m_timeline__updateLazyLoading(timeline);
  });
}

function m_timeline__init(timeline) {
  $(function () {
    //The passed argument has to be at least a empty object or a object with your desired options
    $(".list-container").overlayScrollbars({
      className: "os-theme-block-dark",
      callbacks: {
        onScroll: function (e) {
          m_timeline__updateLazyLoading(timeline);
        },
      },
    });
  });
}

function m_timeline__refreshItemsHolder(timeline) {
  var itemsHolder = timeline.find(".list-container > .list");
  var totalWidth = 0;
  itemsHolder.find(".m_timeline--item").each(function (i) {
    totalWidth += $(this).outerWidth(true);
  });
  itemsHolder.css("width", totalWidth);
  timeline.attr("data-listwidth", totalWidth);
}

// source: http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport/7557433#7557433
function m_timeline__isElementInViewport(el) {
  var rect = el.getBoundingClientRect();
  return (
    rect.bottom >= 0 &&
    rect.right >= 0 &&
    rect.top <= $(window).height() &&
    rect.left <= $(window).width()
  );
}
